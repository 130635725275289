<template>
    <CModal title="Tập đoàn Hoàng Minh Trung"
            color="warning"
            :show.sync="show">
        {{message}}

        <template v-slot:footer>
            <CButton type="button" color="primary" @click="handleOk">{{ $t('common.close') }}</CButton>
        </template>
    </CModal>
</template>

<script>    
    import { mapState } from 'vuex'

    export default {
        name: 'SystemAlert',
        computed: {
            ...mapState('alert', ['message', 'type', 'show'])
        },
        methods: {
            handleOk() {
                this.$store.dispatch('alert/hide', { root: true });
            }
        }
    }
</script>
